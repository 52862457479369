.display-flex {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: -moz-inline-box;
  display: -webkit-inline-box;
}
.display-flex-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.logo {
  margin: auto;
}
.image-section-footer a {
  font-size: 16px;
  color: #fff;
}
.help-block{
  color: red;
}
.image-section-footer a:hover {
  text-decoration: underline;
}
.wle p {
  color: #fff;
  font-size: 40px;
  text-align: center;
  margin: 0 0 100px;
}

.input-label {
  margin: 0 0 25px 0;
}
.input-label .input {
  border-radius: 6px;
  border: 1px solid #cccccc;
  font-size: 14px;
  height: 60px;
  width: 100%;
  background: transparent;
  font-family: "noto_sansregular";
  z-index: 1;
  padding: 0 20px 0 60px;
}
.input-label .input:focus {
  outline: 0;
}
.input-label-icon span {
  width: 60px;
  height: 52px;
  background: url(../../assets/img/icon-bg-big.png) no-repeat;
  z-index: 0;
  position: absolute;
  background-size: 160px;
}
.input-label-icon span.input-usesrName {
  background-position: 5px 4px;
}
.input-label-icon span.icon-lock {
  background-position: -12px -103px;
  background-size: 263px;
}

.login-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  min-height: 100vh;
  background: #fff;
}
.login-wrapper .form-inner-container {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 70px;
}

.login-wrapper .image-section {
  flex: 1;
  background: rgb(10, 43, 86);
  background: linear-gradient(
    52deg,
    rgba(10, 43, 86, 1) 0%,
    rgba(1, 102, 174, 1) 100%
  );
  position: relative;
}
.image-section:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../../assets/img/home1_desktop.png) no-repeat right center;
  background-size: cover;
}

.image-content {
  flex-direction: column;
  padding: 70px;
  position: relative;
  z-index: 11;
  height: 100%;
}

/*F O R M    S E C T I O N*/

.login-wrapper .form-section {
  flex-basis: 630px;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.submit-button {
  height: 60px;
  padding: 10px 50px;
  border: 0;
  color: #fff;
  font-size: 18px;
  margin: 0 auto;
  background-color: #37b470;
  border-radius: 5px;
  width: 100%;
  font-weight: 500;
}
.submit-button:focus {
  outline: 0;
  border: 0;
}

@media only screen and (max-width: 1200px) {
  .login-wrapper .form-section {
    flex-basis: auto;
    flex: 1;
  }
}
@media only screen and (min-width: 769px) {
  .form-section .wle p {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .image-section {
    display: flex;
    align-items: center;
  }
  .wle p {
    margin: 0 0 10px;
  }
  .login-wrapper {
    flex-direction: column;
    background-color: #ced4e2;
  }
  .login-wrapper .form-section,
  .login-wrapper .image-section,
  .image-section:after {
    background-color: transparent;
  }
  .image-content {
    padding: 20px;
  }
  .login-wrapper .form-inner-container {
    padding: 15px;
    margin: 0 15px 15px;
    width: calc(100% - 30px);
    border-radius: 8px;
    background: #fff;
  }
  .login-wrapper .form-section-header h2 {
    font-size: 25px;
  }
  .login-wrapper:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 150px;
    background: rgb(10, 43, 86);
    background: linear-gradient(
      52deg,
      rgba(10, 43, 86, 1) 0%,
      rgba(1, 102, 174, 1) 100%
    );
  }
  .image-section-footer,
  .image-section .wle p,
  .input-label-icon span {
    display: none;
  }
  .login-wrapper .form-section {
    position: relative;
    z-index: 11;
  }
  .login-wrapper .form-section {
    flex: unset;
  }
  .input-label .input {
    height: 40px;
    padding: 0 20px;
  }
}
