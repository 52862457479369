.modal{
    display: flex !important;
    z-index: 120;
    overflow: auto;
    margin: auto auto !important;
    @media (min-width: 768px){
    }
}

.modal-dialog{
    @media (min-width: 768px){
        margin: auto auto !important;
    }
}