@font-face {
  font-family: "noto_sansregular";
  src: url("./assets/fonts/notosans-regular-webfont.eot");
  src: url("./assets/fonts/notosans-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/notosans-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/notosans-regular-webfont.woff") format("woff"),
    url("./assets/fonts/notosans-regular-webfont.ttf") format("truetype"),
    url("./assets/fonts/notosans-regular-webfont.svg#noto_sansregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "noto_sanslight";
  src: url("./assets/fonts/notosans-light-webfont.eot");
  src: url("./assets/fonts/notosans-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/notosans-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/notosans-light-webfont.woff") format("woff"),
    url("./assets/fonts/notosans-light-webfont.ttf") format("truetype"),
    url("./assets/fonts/notosans-light-webfont.svg#noto_sanslight")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

html {
  position: relative;
  min-height: 100%;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  line-height: 1.42857143;
  color: #444444;
  font-family: "noto_sansregular";
  font-size: 14px;
  /* background-color: #001c32; */
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}
a {
  color: #727272;
  text-decoration: none;
}
img {
  max-width: 100%;
  width: 100%;
}
.table_des {
  font-size: 17px;
  font-weight: 700;
  color: gray;
}
.marginLeft {
  margin-left: 10px;
}
.content_wrapper {
  min-height: 100%;
  margin-left: 230px !important;
  z-index: 800;
}
.form-control {
  width: 100%;
}
.searchBox {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  /* background-image: url("./search_icon.png"); */
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}
.form_fields {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}
.select_join select {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}
.select_join select:focus {
  outline: none;
}
/* Style the search field */
form.example input[type="text"] {
  padding: 10px;
  font-size: 17px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 2px;
  border-right: none;
  float: left;
  width: 80%;
  /* background: #f1f1f1; */
}

/* Style the submit button */
form.example button {
  float: left;
  width: 20%;
  padding: 10px;
  color: grey;
  /* background: #2196f3; */
  color: white;
  font-size: 17px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 2px;
  border-left: none; /* Prevent double borders */
  cursor: pointer;
}
.item-style {
  color: black;
}
.pt-20 {
  padding-top: 20px;
}
.pagination_ ul {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.pagination_ ul li {
  margin: 0 5px;
}
.pagination_ ul li.active a {
  background: #3d5b99;
  color: #fff;
}
.pagination_ ul li a {
  border-color: #ccc;
  border-radius: 0;
  color: #444;
  padding: 10px 17px !important;
  text-transform: uppercase;
  font-size: 14px;

  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #444;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.pagination_ ul li a:not(:disabled):not(.disabled) {
  cursor: pointer;
}
