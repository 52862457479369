.treeview-menu {
  display: block !important;
}
.treeview{
  cursor: pointer;
}
.main-sidebar{
  z-index: 1111;
  padding-top:0;
  .sidebar{
    h3{
      margin-top: 12px;
      text-align: center;
    }
  }
}