.loader-wrap {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  height: 100%;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  z-index: 1000;
  i {
    img {
      width: 150px;
      height: 150px;
    }
  }
  @media screen and (max-width: 820px) {
    top: 0;
    bottom: 0;
    left: 0;
    align-self: center;
    right: 0;
    width: 100%;
  }
}
