#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 12px 20px;
  position: fixed;
  z-index: 999;
  left: 50%;
  bottom: 40px;
  font-size: 16px;
  position: fixed;
  visibility: visible;
  -webkit-animation: fadein 2s, fadeout 4s 2.5s;
  animation: fadein 2s, fadeout 4s 2.5s;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  0% {
    bottom: 0;
    opacity: 0;
  }
  50% {
    bottom: 30px;
    opacity: 1;
  }
  100% {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    bottom: 0;
    opacity: 0;
  }
  50% {
    bottom: 30px;
    opacity: 1;
  }
  100% {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
